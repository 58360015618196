@use '@button' as *;
@use '@fixed-size' as *;
@use '@queries' as *;
@use '@typography' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--cornerradius16);
  box-shadow: 0 0 0 2px var(--cst-price-card-outline-add);
  padding: 16px;

  @include min-1440-break {
    padding: 20px;
    gap: 20px;
  }
  .button {
    @include buttonSizes('42', '42', '42', '42', '54', '54');
  }
}

.info {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2px;

  .selected {
    color: var(--cst-cards-item-page-newprice);
    @include typography-xl;
    @include weight-semi-bold;

    @include min-1440-break {
      @include typography-3xl;
    }
  }

  .all {
    color: var(--cst-cards-item-page-oldprice);

    @include typography-m;
    @include weight-bold;

    @include min-1440-break {
      @include typography-xl;
    }
  }
}

.subtitle {
  color: #606060;
}
