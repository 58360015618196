@use '@button' as *;
@use '@fixed-size' as *;
@use '@tabs' as *;
@use '@queries' as *;
@use '@typography' as *;

.filter-tab {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: var(--cornerradius8);
  @include fixedSize(max-content, 34px);
  padding: 7px 12px;
  gap: 8px;

  @include min-428-break {
    @include fixedSize(max-content, 42px);
  }

  @include min-1024-break {
    border-radius: var(--cornerradius6);
    @include fixedSize(max-content, 32px);
    padding: 7px 10px;
  }

  @include min-1440-break {
    border-radius: var(--cornerradius10);
    padding: 12.5px 18px;
    @include fixedSize(max-content, 46px);
  }
}

.variant {
  &--primary {
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-default);
    background: var(--cst-tabitem-primary-background-default);
    span[id='filter-tab--icon'],
    span[id='filter-tab--icon'] > svg {
      @include singleFixedSize(14px);

      @include min-1440-break {
        @include singleFixedSize(18px);
      }
    }

    .filter-tab--text {
      color: var(--cst-tabitem-primary-text-default);
      @include typography-s;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-base;
      }
    }
  }

  &--secondary,
  &--tertiary {
    span[id='filter-tab--icon'],
    span[id='filter-tab--icon'] > svg {
      @include singleFixedSize(20px);

      @include min-1024-break {
        @include singleFixedSize(16px);
      }

      @include min-1440-break {
        @include singleFixedSize(20px);
      }
    }
  }

  &--secondary {
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-default);
    background: var(--cst-tabitem-secondary-background-default);

    .filter-tab--text {
      color: var(--cst-tabitem-secondary-text-default);
      @include typography-s;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-base;
      }
      @include min-1024-break {
        @include typography-s;
      }

      @include min-1440-break {
        @include typography-base;
      }
    }
  }

  &--tertiary {
    span[id='filter-tab--icon'],
    span[id='filter-tab--icon'] > svg {
      color: #40bb18;
    }

    box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-default);
    background: var(--cst-tabitem-tertiary-background-default);

    .filter-tab--text {
      color: var(--cst-tabitem-tertiary-text-default);
      @include typography-s;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-base;
      }
      @include min-1024-break {
        @include typography-s;
      }

      @include min-1440-break {
        @include typography-base;
      }
    }
  }
}
