@use '@tabs' as *;
@use '@queries' as *;

.filters,
.filters > ul {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: max-content !important;
  flex-wrap: nowrap !important;
  scrollbar-width: none;
  overflow: auto;
  margin: 0 -14px;
  padding: 0 14px;
  @include min-744-break {
    margin: 0 -28px;
    padding: 0 28px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
